<template>
  <div class="fla-container">
    <!-- 悬浮球 -->
    <div
      class="floating-ball"
      :style="{ left: position.x + 'px', top: position.y + 'px' }"
      @mousedown="startDrag"
      @touchstart="startDrag"
      @click="toggleInputBox"
      style="cursor: pointer"
    >
      <i class="el-icon-s-tools"></i>
    </div>

    <!-- 主面板 -->
    <div
      v-if="showInputBox"
      class="main-panel"
      :style="{ left: position.x + 'px', top: position.y + 60 + 'px' }"
    >
      <div class="panel-header">
        <h3>Steam账号管理</h3>
        <el-button icon="el-icon-close" circle @click="toggleInputBox"></el-button>
      </div>

      <div class="panel-content">
        <!-- 左侧用户列表 -->
        <div class="user-list">
          <div class="list-header">
            <h4>已绑定账号</h4>
            <el-button 
              type="primary" 
              icon="el-icon-plus" 
              circle 
              @click="showTokenPanel"
              :disabled="steamuserStore.state.users.length > 0"
            ></el-button>
          </div>

          <div class="user-items">
            <div
              v-for="(item, index) in steamuserStore.state.users"
              :key="index"
              class="user-item"
              :class="{ active: activeIndex === index }"
              @click="selectItem(index, item)"
            >
              <el-avatar :size="40" :src="item.avatar"></el-avatar>
              <div class="user-info">
                <span class="username">{{ item.personaname }}</span>
                <span class="steam-id">ID: {{ item.steamid }}</span>
              </div>
              <el-button 
                type="danger" 
                icon="el-icon-delete" 
                circle 
                size="mini"
                @click.stop="deleteuser(item.account_name)"
              ></el-button>
            </div>
          </div>
        </div>

        <!-- 右侧内容区 -->
        <div class="content-area">
          <!-- 用户详情 -->
          <div v-if="showContent" class="user-detail">
            <div class="user-profile">
              <el-avatar :size="60" :src="nowitem.avatar"></el-avatar>
              <div class="profile-info">
                <h3>{{ nowitem.personaname }}</h3>
                <p class="steam-id">Steam ID: {{ nowitem.steamid }}</p>
              </div>
            </div>

            <div class="token-info">
              <h4>Access Token 信息</h4>
              <div class="token-meta">
                <el-tag type="info" effect="plain">
                  <i class="el-icon-time"></i>
                  签发于 {{ timeSinceIssued }}
                </el-tag>
                <el-tag type="success" effect="plain">
                  <i class="el-icon-timer"></i>
                  剩余 {{ remainingTime }}
                </el-tag>
              </div>
              <div class="token-value">
                <el-input
                  type="textarea"
                  v-model="nowitem.access_token"
                  :rows="3"
                  readonly
                ></el-input>
              </div>
            </div>
          </div>

          <!-- Token绑定面板 -->
          <div v-if="showToken" class="token-panel">
            <el-tabs v-model="activeTab" @tab-click="handleTabClick">
              <el-tab-pane label="输入Token" name="token">
                <div class="token-input-section" v-loading="tokenhand">
                  <el-input
                    v-model="token"
                    placeholder="请输入Steam Access Token"
                    class="token-input"
                  >
                    <template slot="prepend">
                      <i class="el-icon-key"></i>
                    </template>
                  </el-input>
                  <el-button type="primary" @click="inputtokenway">
                    绑定账号
                  </el-button>
                  <el-input
                      type="textarea"
                      v-model="tokenhow"
                      :rows="4"
                      readonly
                  ></el-input>
                </div>
              </el-tab-pane>

              <el-tab-pane label="扫码登录" name="scan">
                <div class="scan-section" v-if="scanTabActive">
                  <steamlogin :key="steamKey"></steamlogin>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Steamlogin from "@/pages/Charts/steamlogin.vue";
import steamuserStore from "@/store/steamuserStore";
import {jwtDecode} from "jwt-decode";
import api from "@/axios/api";

export default {
  name: 'Fla',
  components: {
    Steamlogin
  },
  computed: {
    steamuserStore() {
      return steamuserStore
    },
    decodedToken() {
      return this.nowitem.access_token ? jwtDecode(this.nowitem.access_token) : null;
    },
    timeSinceIssued() {
      if (!this.decodedToken) return '';
      const issuedTime = this.decodedToken.iat * 1000;
      const diffMinutes = Math.floor((Date.now() - issuedTime) / 60000);
      return `${diffMinutes}分钟前`;
    },
    remainingTime() {
      if (!this.decodedToken) return '';
      const diffHours = Math.floor((this.decodedToken.exp * 1000 - Date.now()) / 3600000);
      return `${diffHours}小时`;
    }
  },
  data() {
    return {
      tokenhow:"在浏览器中登陆 Steam：https://store.steampowered.com/ ，登陆之后直接访问 https://store.steampowered.com/pointssummary/ajaxgetasyncconfig 。 提取 webapi_token 部分的内容即为 access_token。",
      tokenhand: false,
      activeIndex: -1,
      showContent: false,
      showToken: false,
      activeTab: 'token',
      token: '',
      steamKey: 0,
      scanTabActive: false,
      position: {x: 100, y: 100},
      dragging: false,
      startX: 0,
      startY: 0,
      preventClick: false,
      showInputBox: false,
      nowitem: {}
    }
  },
  methods: {
    startDrag(event) {
      this.dragging = true;
      this.preventClick = false;
      const e = event.type === "mousedown" ? event : event.touches[0];
      this.startX = e.clientX - this.position.x;
      this.startY = e.clientY - this.position.y;

      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("touchmove", this.onDrag);
      document.addEventListener("mouseup", this.endDrag);
      document.addEventListener("touchend", this.endDrag);
    },

    onDrag(event) {
      if (!this.dragging) return;
      this.preventClick = true;
      const e = event.type === "mousemove" ? event : event.touches[0];

      this.position.x = Math.max(0, Math.min(window.innerWidth - 50, e.clientX - this.startX));
      this.position.y = Math.max(0, Math.min(window.innerHeight - 50, e.clientY - this.startY));
    },

    endDrag() {
      this.dragging = false;
      this.removeEventListeners();
    },

    removeEventListeners() {
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("touchmove", this.onDrag);
      document.removeEventListener("mouseup", this.endDrag);
      document.removeEventListener("touchend", this.endDrag);
    },

    toggleInputBox() {
      if (this.preventClick) {
        this.preventClick = false;
        return;
      }
      this.showInputBox = !this.showInputBox;
    },

    selectItem(index, item) {
      this.activeIndex = index;
      this.showContent = true;
      this.showToken = false;
      this.nowitem = item;
    },

    handleTabClick(tab) {
      if (tab.name === 'scan') {
        this.scanTabActive = true;
        this.steamKey += 1;
      } else {
        this.scanTabActive = false;
      }
    },

    deleteuser(val) {
      this.$confirm('确认删除该账号绑定？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        steamuserStore.commit('removeUser', val);
        this.activeIndex = -1;
        this.showContent = false;
        this.nowitem = null;
        this.$message.success('删除成功');
      }).catch(() => {});
    },

    showTokenPanel() {
      if(steamuserStore.state.users.length > 0) {
        this.$message.warning("当前仅支持绑定一名用户");
        return;
      }
      this.showToken = true;
      this.showContent = false;
      this.activeIndex = -1;
    },

    async inputtokenway() {
      if (!this.token) {
        this.$message.warning('请输入Token');
        return;
      }
      
      this.tokenhand = true;
      try {
        const user = {};
        const payload = jwtDecode(this.token);
        const playerdata = await api.get('/Steam/player', {
          params: {
            steamid: payload.sub,
          }
        })
        console.log(playerdata);
        user.account_name = playerdata.result.steamid
        user.access_token = this.token
        user.refresh_token = null
        user.personaname = playerdata.result.personaname
        user.avatar = playerdata.result.avatarfull
        user.steamid = payload.sub
        steamuserStore.commit('addUser', user)
        console.log("成功2")
      } catch (error) {
        this.$message.error('Token格式错误');
      } finally {
        this.tokenhand = false;
      }
    }
  }
}
</script>

<style scoped>
.fla-container {
  position: fixed;
  z-index: 1000;
}

.floating-ball {
  position: fixed;
  width: 50px;
  height: 50px;
  background: #409EFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.floating-ball:hover {
  transform: scale(1.1);
}

.floating-ball i {
  font-size: 24px;
  color: white;
}

.main-panel {
  position: fixed;
  width: 800px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: #f5f7fa;
  border-bottom: 1px solid #eee;
}

.panel-header h3 {
  margin: 0;
  font-size: 18px;
  color: #2c3e50;
}

.panel-content {
  display: flex;
  height: 500px;
}

.user-list {
  width: 250px;
  border-right: 1px solid #eee;
  display: flex;
  flex-direction: column;
}

.list-header {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.list-header h4 {
  margin: 0;
  color: #2c3e50;
}

.user-items {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.user-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.user-item:hover {
  background: #f5f7fa;
}

.user-item.active {
  background: #ecf5ff;
}

.user-info {
  flex: 1;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.username {
  font-size: 14px;
  color: #2c3e50;
  margin-bottom: 4px;
}

.steam-id {
  font-size: 12px;
  color: #909399;
}

.content-area {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.user-detail {
  height: 100%;
}

.user-profile {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.profile-info {
  margin-left: 15px;
}

.profile-info h3 {
  margin: 0 0 5px 0;
  color: #2c3e50;
}

.token-info {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}

.token-info h4 {
  margin: 0 0 15px 0;
  color: #2c3e50;
}

.token-meta {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.token-value {
  margin-top: 15px;
}

.token-panel {
  height: 100%;
}

.token-input-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
}

.token-input {
  width: 100%;
}

.scan-section {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* 滚动条样式 */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
</style>