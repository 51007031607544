import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        users: JSON.parse(localStorage.getItem('steamusers')) || []// 用户列表数组
    },
    mutations: {
        addUser(state, user) {
            state.users.push(user);
            localStorage.setItem('steamusers', JSON.stringify(state.users));
        },
        removeUser(state, account_name) {
            state.users = state.users.filter(user => user.account_name !== account_name);
            localStorage.setItem('steamusers', JSON.stringify(state.users));
        }
    },
    actions: {
        addUserAsync({ commit }, user) {
            // 可在这里添加异步操作
            commit('addUser', user);
        },
        removeUserAsync({ commit }, account_name) {
            commit('removeUser', account_name);
        }
    }
});