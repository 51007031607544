<template>
  <div class="steam-family">
    <div class="chart-header">
      <h3>Steam家庭共享分析</h3>
      <el-button 
        type="primary" 
        icon="el-icon-refresh" 
        :loading="disver"
        @click="savetoken"
      >
        刷新数据
      </el-button>
    </div>

    <div class="charts-grid">
      <!-- 时间分布图表 -->
      <div class="chart-container main-chart" ref="chartRef" v-loading="disver">
        <div class="loading-placeholder" v-if="disver">
          <i class="el-icon-loading"></i>
          <p>正在获取数据...</p>
        </div>
      </div>

      <!-- 游戏详情表格 -->
      <div class="game-details">
        <el-tabs v-model="activeTab1" @tab-click="handleTabClick1">
          <el-tab-pane v-for="i in gamedetail" :label="i.name" :key="i.name">
            <el-tabs v-model="activeTab" @tab-click="handleTabClick">
              <el-tab-pane label="被别人游玩游戏" name="beibaipiao">
                <el-table
                    :data="i.beibaipiaogame"
                    style="width: 100%"
                    height="300"
                    :stripe="true"
                    :border="true"
                >
                  <el-table-column prop="gamename" label="游戏名称" min-width="180">
                    <template slot-scope="scope">
                      <div class="game-info">
                        <el-image
                            :src="getGameImage(scope.row.gameid)"
                            fit="cover"
                            class="game-image"
                        ></el-image>
                        <span>{{ scope.row.gamename }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="gametime" label="游玩时间" width="120">
                    <template slot-scope="scope">
                      {{ (scope.row.gametime / 3600).toFixed(1) }}小时
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="游玩别人游戏" name="baipiao">
                <el-table
                    :data="i.baipiaogame"
                    style="width: 100%"
                    height="300"
                    :stripe="true"
                    :border="true"
                >
                  <el-table-column prop="gamename" label="游戏名称" min-width="180">
                    <template slot-scope="scope">
                      <div class="game-info">
                        <el-image
                            :src="getGameImage(scope.row.gameid)"
                            fit="cover"
                            class="game-image"
                        ></el-image>
                        <span>{{ scope.row.gamename }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="gametime" label="游玩时间" width="120">
                    <template slot-scope="scope">
                      {{ (scope.row.gametime / 3600).toFixed(1) }}小时
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>

        </el-tabs>
      </div>
    </div>

    <div class="chart-legend">
      <div class="legend-item">
        <span class="legend-color" style="background: #67C23A"></span>
        <span>净游玩时间</span>
      </div>
      <div class="legend-item">
        <span class="legend-color" style="background: #E6A23C"></span>
        <span>白嫖时间</span>
      </div>
      <div class="legend-item">
        <span class="legend-color" style="background: #F56C6C"></span>
        <span>被白嫖时间</span>
      </div>
    </div>
  </div>
</template>

<script>
import steamuserStore from "@/store/steamuserStore";
import api from "@/axios/api";
import * as echarts from 'echarts';

export default {
  name: 'SteamFamily',
  data() {
    return {
      gamedetail:[],
      disver: false,
      baipiao: [],
      beibaipiao: [],
      jingbaipiao: [],
      username: [],
      chartInstance: null,
      activeTab: 'beibaipiao',
      activeTab1: '',
      beibaipiaoGames: [],
      baipiaoGames: [],
      gameCache: new Map(),
      chartOptions: {
        tooltip: {
          trigger: 'axis',
          axisPointer: { 
            type: 'shadow',
            shadowStyle: {
              color: 'rgba(0, 0, 0, 0.1)'
            }
          },
          formatter: function(params) {
            let result = params[0].axisValue + '<br/>';
            params.forEach(param => {
              const value = param.value;
              const sign = value >= 0 ? '+' : '';
              result += `${param.marker} ${param.seriesName}: ${sign}${value}小时<br/>`;
            });
            return result;
          }
        },
        legend: {
          show: false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}小时'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E4E7ED'
            }
          }
        },
        yAxis: {
          type: 'category',
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            formatter: function(value) {
              return value.length > 15 ? value.substring(0, 15) + '...' : value;
            }
          },
          data: []
        },
        series: [
          {
            name: '净游玩',
            type: 'bar',
            stack: 'Total',
            label: { 
              show: true, 
              position: 'right',
              formatter: '{c}小时'
            },
            itemStyle: {
              color: '#67C23A'
            },
            emphasis: { 
              focus: 'series',
              itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(103, 194, 58, 0.5)'
              }
            },
            data: []
          },
          {
            name: '白嫖',
            type: 'bar',
            stack: 'Total',
            label: { 
              show: true, 
              position: 'right',
              formatter: '{c}小时'
            },
            itemStyle: {
              color: '#E6A23C'
            },
            emphasis: { 
              focus: 'series',
              itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(230, 162, 60, 0.5)'
              }
            },
            data: []
          },
          {
            name: '被白嫖',
            type: 'bar',
            stack: 'Total',
            label: { 
              show: true, 
              position: 'left',
              formatter: '{c}小时'
            },
            itemStyle: {
              color: '#F56C6C'
            },
            emphasis: { 
              focus: 'series',
              itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(245, 108, 108, 0.5)'
              }
            },
            data: []
          }
        ]
      }
    }
  },
  computed: {
    gamestore() {
      return steamuserStore.state.users
    }
  },
  watch: {
    username() {
      this.updateChartData()
    }
  },
  mounted() {
    this.initChart()
    window.addEventListener('resize', this.resizeChart)
  },
  beforeDestroy() {
    if (this.chartInstance) {
      this.chartInstance.dispose()
    }
    window.removeEventListener('resize', this.resizeChart)
  },
  methods: {
    initChart() {
      this.chartInstance = echarts.init(this.$refs.chartRef)
      this.chartInstance.setOption(this.chartOptions)
    },
    resizeChart() {
      this.chartInstance && this.chartInstance.resize()
    },
    updateChartData() {
      this.chartInstance.setOption({
        yAxis: [{ data: this.username }],
        series: [
          { data: this.jingbaipiao },
          { data: this.baipiao },
          { data: this.beibaipiao }
        ]
      })
    },
    handleTabClick(tab) {
      this.activeTab = tab.name
    },
    handleTabClick1(tab) {
      this.activeTab1 = tab.name
    },
    async getGameName(appId) {
      if (this.gameCache.has(appId)) {
        return this.gameCache.get(appId)
      }
      try {
        const response = await api.get(`/Steam/game/${appId}`)
        const gameName = response.result.name
        this.gameCache.set(appId, gameName)
        return gameName
      } catch (error) {
        console.error('获取游戏名称失败:', error)
        return appId
      }
    },
    getGameImage(appId) {

      return `https://media.st.dl.eccdnx.com/steam/apps/${appId}/header.jpg`
    },
    savetoken() {
      if(!steamuserStore.state.users[0]){
        this.$message.warning("请先绑定Steam账号")
        return
      }
      this.disver = true
      // 清空数据
      this.username = []
      this.beibaipiao = []
      this.baipiao = []
      this.jingbaipiao = []
      this.beibaipiaoGames = []
      this.baipiaoGames = []
      this.gameCache.clear()

      api.get("/Steam/getfamilycount", {
        timeout: 100000,
        params: {
          accesstoken: steamuserStore.state.users[0].access_token,
        }
      })
      .then(res => {
        this.gamedetail=res.result
        Object.entries(res.result).forEach(([, value]) => {
          this.username.push(value.name)
          this.beibaipiao.push(-((value.Beibaipiao / 60 / 60).toFixed(1)))
          this.baipiao.push((value.Baipiao / 60 / 60).toFixed(1))
          this.jingbaipiao.push(((value.Baipiao - value.Beibaipiao) / 60 / 60).toFixed(1))
          
          // 添加游戏详情数据
          // if (value.beibaipiaogame && value.beibaipiaogame.length > 0) {
          //   this.beibaipiaoGames.push(...value.beibaipiaogame)
          // }
          // if (value.baipiaogame && value.baipiaogame.length > 0) {
          //   this.baipiaoGames.push(...value.baipiaogame)
          // }
        })
        this.disver = false
        this.$message.success('数据更新成功')
      })
      .catch(error => {
        this.disver = false
        this.$message.error('获取数据失败，请重试')
        console.error('获取数据失败:', error)
      })
    }
  }
}
</script>

<style scoped>
.steam-family {
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.chart-header h3 {
  margin: 0;
  font-size: 18px;
  color: #2c3e50;
}

.charts-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.chart-container {
  width: 100%;
  height: 400px;
  position: relative;
  background: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
}

.loading-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1;
}

.loading-placeholder i {
  font-size: 30px;
  color: #409EFF;
  margin-bottom: 10px;
}

.loading-placeholder p {
  color: #666;
  margin: 0;
}

.game-details {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
}

.game-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.game-image {
  width: 40px;
  height: 20px;
  border-radius: 4px;
}

.chart-legend {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.legend-color {
  width: 12px;
  height: 12px;
  border-radius: 2px;
}

.legend-item span:last-child {
  color: #666;
  font-size: 14px;
}

@media screen and (max-width: 1200px) {
  .charts-grid {
    grid-template-columns: 1fr;
  }
}
</style>