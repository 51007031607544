<script>
import fla from "@/pages/Charts/fla.vue";
import steamFamily from "@/pages/Charts/steamFamily.vue";

export default {
  name: 'SteamData',
  components: {
    fla,
    steamFamily
  }
}
</script>

<template>
  <div class="steam-data">
    <fla></fla>
    <div class="steam-header">
      <h2>Steam平台数据分析</h2>
      <p class="subtitle">深入了解Steam平台的游戏数据分布和趋势</p>
    </div>

    <div class="steam-content">
      <!-- Steam家庭共享分析 -->
      <div class="data-section">
        <div class="section-header">
          <h3>Steam家庭共享分析</h3>
          <p>分析Steam家庭共享功能的游戏时长情况</p>
        </div>
<!--        <div class="chart-container">-->
<!--        </div>-->
        <steamFamily></steamFamily>
      </div>
    </div>

    <!-- 数据说明 -->
    <div class="data-info">
      <div class="info-card">
        <i class="el-icon-info"></i>
        <div class="info-content">
          <h4>数据来源</h4>
          <p>数据来源于Steam官方API和公开数据集</p>
        </div>
      </div>
      <div class="info-card">
        <i class="el-icon-time"></i>
        <div class="info-content">
          <h4>更新频率</h4>
          <p>数据每日更新，确保信息实时性</p>
        </div>
      </div>
      <div class="info-card">
        <i class="el-icon-data-analysis"></i>
        <div class="info-content">
          <h4>统计方法</h4>
          <p>采用科学的统计方法，确保数据准确性</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.steam-data {
  padding: 20px;
  background-color: #f5f7fa;
  min-height: 100vh;
}

.steam-header {
  text-align: center;
  margin-bottom: 40px;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.steam-header h2 {
  font-size: 28px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.subtitle {
  color: #666;
  font-size: 16px;
}

.steam-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 30px;
  margin-bottom: 40px;
}

.data-section {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.data-section:hover {
  transform: translateY(-5px);
}

.section-header {
  margin-bottom: 20px;
  text-align: center;
}

.section-header h3 {
  font-size: 20px;
  color: #2c3e50;
  margin-bottom: 8px;
}

.section-header p {
  color: #666;
  font-size: 14px;
}

.chart-container {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
}

.data-info {
  text-align: left;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 40px;
}

.info-card {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.info-card:hover {
  transform: translateY(-2px);
}

.info-card i {
  font-size: 24px;
  color: #409EFF;
  margin-right: 15px;
  margin-top: 3px;
}

.info-content h4 {
  font-size: 16px;
  color: #2c3e50;
  margin-bottom: 8px;
}

.info-content p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}

/* 响应式设计 */
@media screen and (max-width: 768px) {
  .steam-content {
    grid-template-columns: 1fr;
  }

  .chart-container {
    min-height: 300px;
  }

  .steam-header h2 {
    font-size: 24px;
  }

  .subtitle {
    font-size: 14px;
  }
}
</style>