<template>
  <div class="steam-login"  v-loading="imageload">
    <qrcode-vue
        :value="qrUrl"
        :size="170"
        level="H"
        foreground="#1B2838"
        background="#FFFFFF"
        v-if="qrUrl"
    />
    <p v-if="error" class="error">{{ error }}</p>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import api from "@/axios/api";
import steamuserStore from "@/store/steamuserStore";
import {jwtDecode} from "jwt-decode";

export default {
  components: {QrcodeVue},
  data() {
    return {
      imageload:false,
      qrUrl: '',
      clientId: '',
      requestId: '',
      countdown: 300, // 5分钟有效期
      error: '',
      pollInterval: null,
      countdownTimer: null
    }
  },
  mounted() {
    this.initAuthSession()
  },
  beforeDestroy() {
    // 清除定时器
    if (this.pollInterval) {
      clearInterval(this.pollInterval)
    }
  },
  methods: {

    clearPolling() {
      if (this.pollInterval) {
        clearInterval(this.pollInterval)
        this.pollInterval = null
      }
    },
    async initAuthSession() {
      try {
        this.imageload = true;
        // 清除已有轮询
        this.clearPolling()

        const data = await api.post('/api/steam/auth/init')
        this.imageload = false;
        this.clientId = data.response.client_id
        this.requestId = data.response.request_id
        this.qrUrl = data.response.challenge_url

        this.pollAuthStatus()
      } catch (err) {
        this.error = '二维码生成失败'
      }
    },

    async pollAuthStatus() {
      // 清除旧轮询
      this.clearPolling()

      this.pollInterval = setInterval(async () => {
        try {
          const data = await api.get('/api/steam/auth/poll', {
            params: {
              clientId: this.clientId,
              requestId: this.requestId
            }
          })

          if (!data.response) {
            throw new Error('无效的服务器响应')
          }
          // 处理三种状态
          if (data.response.had_remote_interaction === false) {
            if (data.response.new_challenge_url) {
              this.qrUrl = data.response.new_challenge_url
              this.clientId = data.response.new_client_id
            }
          } else if (data.response.had_remote_interaction === true) {
            console.log("成功")
            if (data.response.access_token) {
              const user={}
              const token = data.response.access_token;
              const payload = jwtDecode(token);
              const playerdata = await api.get('/Steam/player', {
                params: {
                  steamid: payload.sub,
                }
              })
              console.log(playerdata)
              user.account_name = data.response.account_name
              user.access_token = data.response.access_token
              user.refresh_token = data.response.refresh_token
              user.personaname = playerdata.result.personaname
              user.avatar = playerdata.result.avatarfull
              user.steamid = payload.sub
              steamuserStore.commit('addUser', user)
              console.log("成功2")
              this.clearPolling()
              // this.$emit('login-success', data.token)
              return
            }
          } else {
            // 无有效数据时重新初始化
            this.clearPolling()
            this.initAuthSession()
          }

        } catch (err) {
          console.error('轮询错误:', err)
          this.error = '登录状态检查失败'
          this.clearPolling()
          this.initAuthSession()
        }
      }, 2000)
    }
  }
}
</script>